import Store from '@/store/index.js'

const Layout = () => import('@/pages/Profile/Layout/index.vue')
const People = () => import('@/pages/Profile/Team/People/index.vue')
const Info = () => import('@/pages/Profile/Team/Info/index.vue')
const TeamSidebar = () => import('@/pages/Profile/Team/TeamSidebar/index.vue')
const TeamDelete = () => import('@/pages/Profile/Team/TeamDelete/index.vue')

// Account
const MyProfileSidebar = () =>
  import('@/pages/Profile/MyProfile/MyProfileSidebar/index.vue')

const AccountInfo = () =>
  import('@/pages/Profile/MyProfile/AccountInfo/index.vue')
const AccountPassword = () =>
  import('@/pages/Profile/MyProfile/AccountPassword/index.vue')
const AccountLicense = () =>
  import('@/pages/Profile/MyProfile/AccountLicense/index.vue')
const AccountDelete = () =>
  import('@/pages/Profile/MyProfile/AccountDelete/index.vue')

// Billing
const BillingSidebar = () =>
  import('@/pages/Profile/Billing/BillingSidebar/index.vue')
const BillingOverview = () =>
  import('@/pages/Profile/Billing/Overview/index.vue')
const BillingPaymentMethods = () =>
  import('@/pages/Profile/Billing/PaymentMethods/index.vue')
const BillingInvoicesHistory = () =>
  import('@/pages/Profile/Billing/InvoicesHistory/index.vue')
const EnterprisePayment = () =>
  import('@/pages/Profile/Billing/EnterprisePayment/index.vue')

export default [
  {
    path: '/team',
    component: Layout,
    meta: {
      layout: 'profile-layout',
    },

    beforeEnter: (to, from, next) => {
      if (
        Store.getters['account/trialHasEnded'] &&
        Store.getters['account/trialNotInterrupted']
      ) {
        next({ name: 'trial-info' })
      } else if (
        Store.getters['account/hasOrganization'] &&
        Store.getters['account/isTeamAvailable']
      ) {
        next()
      } else {
        // TODO: add redirect for private accounts
        next({ name: 'profile-account-info', replace: true })
      }
    },

    children: [
      {
        path: '',
        name: 'profile',
        redirect: { name: 'profile-people' },
      },

      {
        path: 'learners',
        name: 'profile-people',

        components: {
          default: People,
          LeftSidebar: TeamSidebar,
        },
      },

      {
        path: 'organization-info',
        name: 'profile-organization-info',

        components: {
          default: Info,
          LeftSidebar: TeamSidebar,
        },
      },

      {
        path: 'delete',
        name: 'profile-organization-delete',

        beforeEnter: (to, from, next) => {
          if (Store.getters['account/isOwnerRole']) {
            next()
          } else {
            next({ name: 'profile-account-info', replace: true })
          }
        },

        components: {
          default: TeamDelete,
          LeftSidebar: TeamSidebar,
        },
      },
    ],
  },
  {
    path: '/profile',
    component: Layout,
    meta: {
      layout: 'profile-layout',
    },

    beforeEnter: (to, from, next) => {
      if (
        Store.getters['account/trialHasEnded'] &&
        Store.getters['account/trialNotInterrupted']
      ) {
        next({ name: 'trial-info' })
      } else {
        next()
      }
    },

    children: [
      {
        path: '',
        name: 'my-profile',
        redirect: { name: 'profile-account-info' },
      },

      {
        path: 'account_info',
        name: 'profile-account-info',

        components: {
          default: AccountInfo,
          LeftSidebar: MyProfileSidebar,
        },
      },

      {
        path: 'password',
        name: 'profile-account-password',

        components: {
          default: AccountPassword,
          LeftSidebar: MyProfileSidebar,
        },
      },
      {
        path: 'licence',
        name: 'profile-account-license',

        components: {
          default: AccountLicense,
          LeftSidebar: MyProfileSidebar,
        },
      },
      {
        path: 'delete',
        name: 'profile-account-delete',

        beforeEnter: (to, from, next) => {
          if (
            Store.getters['account/isIndividualType'] ||
            (!Store.getters['account/isOwnerRole'] &&
              !Store.getters['account/isMemberRole'])
          ) {
            next()
          } else {
            next({ name: 'profile-account-info', replace: true })
          }
        },

        components: {
          default: AccountDelete,
          LeftSidebar: MyProfileSidebar,
        },
      },
    ],
  },

  {
    path: '/billing',
    component: Layout,
    meta: {
      layout: 'profile-layout',
      trialHasEnded: true,
    },

    beforeEnter: (to, from, next) => {
      if (
        Store.getters['account/trialHasEnded'] &&
        Store.getters['account/trialNotInterrupted']
      ) {
        next({ name: 'trial-info' })
      } else if (Store.getters['account/isBillingAvailable']) {
        next()
      } else {
        next({ name: 'profile-account-info', replace: true })
      }
    },

    children: [
      {
        path: '',
        name: 'overview',
        redirect: { name: 'billing-overview' },
      },

      {
        path: 'overview',
        name: 'billing-overview',

        components: {
          default: BillingOverview,
          LeftSidebar: BillingSidebar,
        },
      },
      {
        path: 'payment-methods',
        name: 'payment-methods',

        beforeEnter: (to, from, next) => {
          if (!Store.getters['account/isEnterpriseType']) {
            next()
          } else {
            next({ name: 'enterprise-payment', replace: true })
          }
        },

        components: {
          default: BillingPaymentMethods,
          LeftSidebar: BillingSidebar,
        },
      },
      {
        path: 'payment-methods',
        name: 'enterprise-payment',

        beforeEnter: (to, from, next) => {
          if (!Store.getters['account/isEnterpriseType']) {
            next({ name: 'payment-methods', replace: true })
          } else {
            next()
          }
        },

        components: {
          default: EnterprisePayment,
          LeftSidebar: BillingSidebar,
        },
      },
      {
        path: 'invoices',
        name: 'invoices-history',

        components: {
          default: BillingInvoicesHistory,
          LeftSidebar: BillingSidebar,
        },
      },
    ],
  },
]
