export default {
  title: {
    header: 'Passwort setzen',
    text: 'Muss mindestens 8 Zeichen lang sein',
  },

  form: {
    new_pass: ' Erstellen Sie Ihr Passwort',
    confirm_pass: 'Passwort wiederholen',

    current_password: 'Aktuelles Passwort',
    new_password: 'Neues Passwort',
    change: 'Aktualisieren',
  },
}
