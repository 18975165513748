export default {
  payment: {
    paid: 'Bezahlt',
    unpaid: 'Fehler',
    pending: 'Offen',
    canceled: 'Storniert',
  },

  user: {
    active: 'Aktiv',
    pending: 'Offen',
    suspended: 'Pausiert',
    deactivated: 'Inaktiv',
    pre_registered: 'Pre-registered',
  },

  license: {
    generated: 'Generated',
    confirmed: 'Confirmed',
    canceled: 'Fehler',
    activated: 'Aktiv',
    expired: 'Expired',
  },

  organisation: {
    business: 'Business',
    enterprise: 'Business Pro',
    individual: 'Privat',
    grunder_service: 'Grunder service',
  },
}
