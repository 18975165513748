const state = () => ({
  draftUser: {},

  currentUser: {},
})

const actions = {
  setDraftUser({ commit }, user) {
    commit('signUpSuccess', user)
  },

  setCurrentUser({ commit }, user) {
    commit('loginSuccess', user)
  },

  licenseStatus({ commit }, license) {
    commit('setLicenseStatus', license)
  },

  destroyCurrentUser({ commit }) {
    commit('logout')
  },
}

const mutations = {
  signUpSuccess(state, user) {
    state.draftUser = user
  },

  loginSuccess(state, user) {
    state.currentUser = user
    state.draftUser = null
  },

  logout(state) {
    state.currentUser = null
    state.draftUser = null
  },

  setLicenseStatus(state, license) {
    state.currentUser.license = license
  },

  setOrganizationToUser(state, payload) {
    let org = state.currentUser.organization_status || {}

    org.organization_id = payload.id
    org.organization_name = payload.name

    if (payload.role) {
      org.role = payload.role
    }

    if (payload.status) {
      org.status = payload.status
    }

    state.currentUser.organization_status = org
  },
}

const getters = {
  isAuthenticated: (state) => {
    return !!state.currentUser?.id
  },

  draftUser: (state) => {
    return state.draftUser
  },

  currentUser: (state) => {
    return state.currentUser
  },

  currentUserFullName: (_state, getters) => getters.currentUser?.full_name,

  trialHasEnded: (state) => {
    return state.currentUser?.restrictions?.trial_has_ended
      ? state.currentUser?.restrictions?.trial_has_ended
      : false
    // if (state.currentUser?.restrictions?.trial_has_ended) {
    //   if (state.currentUser?.license?.activated_until) {
    //     const dateNow = new Date()
    //     const dateActivatedUntil = new Date(
    //       state.currentUser.license.activated_until
    //     )

    //     return dateNow.getTime() > dateActivatedUntil.getTime()
    //   }
    // }
  },

  trialNotInterrupted: (state) => {
    return state.currentUser?.restrictions?.system_trial
      ? state.currentUser?.restrictions?.system_trial
      : false
  },

  // Organization
  organization: (_state, getters) => getters.currentUser?.organization_status,
  organizationID: (_state, getters) => getters.organization?.organization_id,
  organizationName: (_state, getters) =>
    getters.organization?.organization_name,
  organizationRole: (_state, getters) => getters.organization?.role,
  organizationStatus: (_state, getters) => getters.organization?.status,
  organizationOwner: (_state, getters) => getters.organization?.owner,

  organizationInvitation: (_state, getters) =>
    getters.currentUser?.show_custom_invitation_page,

  hasOrganization: (_state, getters) => !!getters.organizationID,

  isIndividualType: (_state, getters) =>
    getters.currentUser?.membership_type === 'individual',

  isBusinessType: (_state, getters) =>
    getters.currentUser?.membership_type === 'business',

  isEnterpriseType: (_state, getters) =>
    getters.currentUser?.membership_type === 'enterprise',

  isGrunderServiceType: (_state, getters) =>
    getters.currentUser?.membership_type === 'grunder_service',

  isOwnerRole: (_state, getters) => getters.organizationRole === 'owner',
  isAdminRole: (_state, getters) => getters.organizationRole === 'admin',
  isMemberRole: (_state, getters) => getters.organizationRole === 'member',

  isTeamAvailable: (_state, getters) => {
    return (
      ((getters.isBusinessType || getters.isEnterpriseType) &&
        ['owner', 'admin'].includes(getters.organizationRole)) ||
      (getters.isGrunderServiceType && getters.isOwnerRole) ||
      (getters.isGrunderServiceType &&
        getters.isAdminRole &&
        !getters.organizationInvitation)
    )
  },

  isBillingAvailable: (_state, getters) => {
    return (
      ((getters.isBusinessType ||
        getters.isEnterpriseType ||
        getters.isGrunderServiceType) &&
        getters.isOwnerRole) ||
      getters.isIndividualType
    )
  },

  isOrganizationStatusActive: (_state, getters) =>
    getters.organizationStatus === 'active',

  // License
  license: (_state, getters) => {
    return getters.currentUser?.license
  },
  isLicenseActive: (_state, getters) => {
    return getters.license?.status === 'activated'
  },
  licenseActivatedAt: (_state, getters) => {
    return getters.license?.activated_at
  },
  licenseActivatedUntil: (_state, getters) => {
    return getters.license?.activated_until
  },

  // Promo
  isPromoApplied(_state, getters) {
    return getters.currentUser?.applied_coupon
  },

  // Promo
  isSubscriptionActive(_state, getters) {
    return getters.currentUser?.show_subscribe_action
  },

  // Payment method needed for subscription
  isPaymentMethodNeeded(state) {
    return state.currentUser?.payment_method_needed
  },
}

export const account = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
